














import { Component, Vue } from 'vue-property-decorator'
import Headers from '@/layout/headers.vue'

@Component({
  components: { Headers }
})
export default class App extends Vue {
  get key() {
    return this.$route.path
  }
  ResetPass() {
    this.$router.go(-1)
  }
}
