// index.js是所有模块注册文件

import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import country from './modules/country'
import base from './modules/base'
import keepAlive from './modules/keepAlive'
import getters from './getter'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    keepAlive,
    country,
    base
  },
  getters
})
