











































































import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({})
export default class Headers extends Vue {
  expand: boolean = false
  isLogin: boolean = false
  isMobile: boolean = false
  avatar: string = ''
  currentPage: string = 'Campaigns'
  userInfo: any = {}
  menuList: any = [
    // {
    //   label: 'Home',
    //   path: '/home'
    // },
    {
      label: 'Campaigns',
      path: '/campaigns'
    },
    {
      label: 'My Cooperation',
      path: '/MyCooperatio'
    },
    {
      label: 'Brands',
      path: '/brands'
    }
    // {
    //   label: 'About Us',
    //   path: '/aboutUs'
    // }
  ]
  @Watch('$route', { immediate: true, deep: true })
  routeChange(route: any) {
    const currentMenu = this.menuList.find((item: any) => {
      return route.path.includes(item.path)
    })
    if (currentMenu) {
      this.currentPage = currentMenu.label
    } else {
      this.currentPage = ''
    }
    const hasToken = localStorage.getItem('token')
    this.isLogin = !!hasToken
    if (hasToken) {
      const userInfo = localStorage.getItem('userInfo')
      const user = JSON.parse(userInfo as any)
      this.userInfo = user
      this.avatar = user?.avatar || ''
    }
  }

  gotoFAQ() {
    window.open('https://gotvoom.myshopify.com/pages/faq')
  }
  jumpPage(menu: any) {
    if (menu.path === '/home') {
      window.open('https://gotvoom.myshopify.com/pages/home')
    } else {
      this.currentPage = menu.label
      this.$router.push(menu.path)
    }

  }
  toCampaigns(val: any) {
    // if (!val) {
    //   this.currentPage = 'Campaigns'
    //   this.$router.push('/campaigns')
    // } else if (val === 1) {
    //   this.currentPage = 'My Cooperation'
    //   this.$router.push('/MyCooperatio')
    // }
    window.open('https://info.gotvoom.com/')
  }
  toSetting() {
    this.currentPage = 'Setting'
    this.$router.push('/user')
  }
  toUserInfo() {
    this.$router.push('/user')
  }

  changeLang(val: any) {
    localStorage.setItem('locale', val)
    this.$i18n.locale = val
  }
  login() {
    localStorage.removeItem('token')
    this.goLogin()
  }
  logout() {
    localStorage.clear()
    this.goLogin()
  }
  goLogin() {
    this.$router.push({
      path: '/login/loginModel'
    })
  }
  createAccount() {
    // 创建账号埋点
    (window as any).gtag('event', 'create_account', {
      event_label: '创建账号'
    })
    this.$router.push({
      path: '/login/createAccount'
    })
  }
  // mounted
  mounted() {
    const header: any = document.querySelector('.header-container')
    const ele: any = document.querySelector('.user-dropdown')
    const temEle: any = document.querySelector('.dropdown-menu')
    if (ele) {
      header.addEventListener('mouseleave', () => {
        (temEle as any).classList.remove('show')
      })
      ele.addEventListener('mouseenter', () => {
        (temEle as any).classList.add('show')
      })
    }
  }
}
