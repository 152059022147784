












import { Component, Vue } from 'vue-property-decorator'
import {
  namespace
} from 'vuex-class'
import Headers from '@/layout/headers.vue'
// user是我们注册的模块user
const someModule = namespace('user')
@Component({
  components: { Headers }
})
export default class App extends Vue {
  @someModule.State('version') version: any

  // beforeCreate
  created() {
    const localV = localStorage.getItem('version')
    const runV = this.version
    console.log(localV, runV)
    if (localV !== runV) {
      localStorage.setItem('version', runV)
      window.location.reload()
    }
  }
}
