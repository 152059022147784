<template>
  <div v-if="showToast" class="toast" :class="[toast.position, toast.type]">
    <div v-if="toast.type=='success'" class="toast_container">
      <div class="msg_container">
        <b-icon icon="check-circle" class="successIcon" />
        <div class="msg_text">
          <div class="title">{{ toast.title }}</div>
          <div :class="toast.title?'massage':''" class="msg">{{ toast.massage }}</div>
        </div>
      </div>
      <b-icon icon="x" class="xIcon" @click="close" />
    </div>
    <div v-else-if="toast.type=='warning'" class="toast_container">
      <div class="msg_container">
        <b-icon icon="alert-circle-fill" class="warningIcon" />
        <div class="msg_text">
          <div class="title">{{ toast.title }}</div>
          <div :class="toast.title?'massage':''" class="msg">{{ toast.massage }}</div>
        </div>
      </div>
      <b-icon icon="x" class="xIcon" @click="close" />
    </div>
    <div v-else-if="toast.type=='error'" class="toast_container">
      <div class="msg_container">
        <b-icon icon="x-circle" class="errorIcon" />
        <div class="msg_text">
          <div class="title">{{ toast.title }}</div>
          <div :class="toast.title?'massage':''" class="msg">{{ toast.massage }}</div>
        </div>
      </div>
      <b-icon icon="x" class="xIcon" @click="close" />
    </div>

  </div>
</template>
<script>
export default {
  props: {
    toast: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showToast: false
    }
  },

  watch: {
    showToast(val) {
      const _this = this
      if (val && !this.toast.manualClose) {
        setTimeout(() => {
          _this.showToast = false
        }, _this.toast.duration)
      }
    },
    toast(val) {
      this.showToast = val.show
    }
  },
  methods: {
    close() {
      this.showToast = false
    }
  }
}
</script>
<style  scoped>

.toast {
  width: 36%;
  height: auto;
  background: #fff;
  opacity: 1;
  border-radius: 4px;
  left: 40%;
  position:fixed;
  box-shadow: 0px 3px 20px rgb(0 0 0 / 16%);
  z-index: 999;
}
.toast_container{
  background: #fff;
  border-radius: 8px;
  color: #333;
  text-align: left;
  min-height: 46px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 13px 0;
}
.top{
 top:14%;
}
.middle{
 top:40%;
}
.bottom{
 top:70%;
}
.msg_container{
  line-height: 20px;
  font-size: 14px;
  word-wrap: break-word;
  display: flex;
  align-items: center;
}
.msg_text{
  font-size: 14px;
  line-height: 20px;
  color: #333;
}
.success{
  border-left: 6px solid #3FC29B;
}
.warning{
  border-left: 6px solid #FFB426;
}
.error{
  border-left: 6px solid #FF5124;
}
.successIcon{
  font-size: 16px;
  margin: 0 12px;
  color: #fff;
  background: #3FC29B;
  border-radius: 50%;
}
.warningIcon{
  font-size: 16px;
  margin: 0 12px;
  color: #fff;
  background: #FFB426;
  border-radius: 50%;
}
.errorIcon{
  font-size: 16px;
  margin: 0 12px;
  color: #fff;
  background: #FF5124;
  border-radius: 50%;
}
.xIcon{
  position: absolute;
  right: 13px;
  font-size: 22px;
  cursor: pointer;
}
.title{
  font-size: 16px;
  font-family: Muli;
  font-weight: bold;
  line-height: 24px;
  color: #333333;
}
.massage{
  color: #666;
}
.msg{
  word-break: break-word;
  white-space: normal;
  margin-right: 38px;
}
@media only screen and (max-width:760px) {
  .toast {
    width: 92%;
    left: 4%;
  }
}
@media only screen and (min-width:760px) and (max-width:1200px) {
  .toast {
    width: 45%;
    left: 30%;
  }
}
</style>
