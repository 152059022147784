import router from './router'

const needTokenList = [
  '/user/index',
  '/MyCooperatio/index',
  '/MyCooperatio/detail'
]
router.beforeEach(async(to, from, next) => {
  // determine whether the user has logged in
  const hasToken = localStorage.getItem('token')
  const blackList = ['/login/loginModel']
  if (hasToken && hasToken !== 'undefined') {
    if (blackList.indexOf(to.path) === -1) {
      next()
    } else {
      next(`/campaigns/index`)
    }
  } else {
    /* need token*/
    if (needTokenList.indexOf(to.matched ? to.matched[to.matched.length - 1].path : to.path) !== -1) {
      next(`/login/loginModel?redirect=${to.fullPath}`)
    } else {
      next()
    }
  }
})
