const keepAlive = {
  state: {
    pageArr: [],
    needRefreshData: false,
    refreshParam: {}
  },
  mutations: {
    UPDATE_PAGE(state, val) {
      state.pageArr.push(...val)
      state.pageArr = Array.from(new Set(state.pageArr))
    },
    DELETE_PAGE(state, val) {
      let i = null
      state.pageArr.forEach((el, index) => {
        if (el === val) {
          i = index
        }
      })
      state.pageArr.splice(i, 1)
    },
    UPDATE_REFRESH(state, val) {
      state.needRefreshData = val.needRefreshData
      state.refreshParam = val.refreshParam || {}
    }
  },
  actions: {
  }
}
export default {
  // 表示允许  使用namespaced方法使用该模块，必须有
  namespaced: true,
  ...keepAlive
}
