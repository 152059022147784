import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

/* Layout */
import Layout from '@/layout/layout.vue'
import BlankContainer from '@/layout/BlankContainer.vue'
import Agreement from '@/layout/Agreement.vue'

Vue.use(VueRouter)
export const constantRoutes: RouteConfig[] = [
  {
    path: '/404',
    component: () => import('@/views/error-page/404.vue')
  },
  // {
  //   path: '/401',
  //   component: () => import('@/views/error-page/401.vue')
  // },
  {
    path: '/',
    component: Layout,
    redirect: '/campaigns',
    name: 'Home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/index.vue')
      },
      {
        path: 'login',
        component: () => import('@/views/home/index.vue')
      },
      {
        path: 'signup',
        component: () => import('@/views/home/index.vue')
      }
    ]
  },
  {
    path: '/aboutUs',
    component: Layout,
    redirect: '/aboutUs/index',
    name: 'AboutUs',
    children: [
      {
        path: 'index',
        component: () => import('@/views/aboutUs/index.vue')
      }
    ]
  },
  {
    path: '/faq',
    component: Layout,
    redirect: '/faq/index',
    name: 'FAQ',
    children: [
      {
        path: 'index',
        component: () => import('@/views/faq/index.vue')
      }
    ]
  },
  {
    path: '',
    component: Agreement,
    children: [
      {
        path: '/agreement/termsOfService',
        name: 'termsOfService',
        component: () => import('@/components/policy/terms.vue')
      },
      {
        path: '/agreement/privacyPolicy',
        name: 'privacyPolicy',
        component: () => import('@/components/policy/privacy.vue')
      }
    ]
  },
  {
    path: '/user',
    redirect: '/user/index',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'UserInfo',
        component: () => import('@/views/user/index.vue')
      }
    ]
  },
  {
    path: '/campaigns',
    redirect: '/campaigns/index',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'CampaignsList',
        component: () => import('@/views/campaigns/index.vue')
      },
      {
        path: 'detail',
        name: 'CampaignsDetail',
        component: () => import('@/views/campaigns/campaignsDetail.vue')
      },
      {
        path: 'campaignsApplied',
        name: 'CampaignsApplied',
        component: () => import('@/views/campaigns/CampaignsApplied.vue')
      },
      {
        path: 'CampaignsEmailCheck',
        name: 'CampaignsEmailCheck',
        component: () => import('@/views/campaigns/CampaignsEmailCheck.vue')
      },
      {
        path: 'apply',
        name: 'CampaignsApply',
        component: () => import('@/views/campaigns/components/apply.vue')
      }
    ]
  },
  {
    path: '/brands',
    redirect: '/brands/index',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'BrandsList',
        component: () => import('@/views/brands/index.vue')
      },
      {
        path: 'detail',
        name: 'BrandDetail',
        component: () => import('@/views/brands/brandDetail.vue')
      }
    ]
  },
  {
    path: '/MyCooperatio',
    redirect: '/MyCooperatio/index',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'MyCooperatioIndex',
        component: () => import('@/views/MyCooperatio/index.vue'),
        meta: {
          keepalive: false
        }
      },
      {
        path: 'detail',
        name: 'ApplyDetail',
        component: () => import('@/views/MyCooperatio/applyDetail.vue')
      }
    ]
  },
  {
    path: '',
    component: BlankContainer,
    children: [
      {
        path: '',
        component: () => import('@/views/login/index.vue'),
        children: [
          {
            path: '/login/loginModel',
            component: () => import('@/views/login/component/loginCom.vue')
          },
          {
            path: '/login/createAccount',
            component: () => import('@/views/login/component/createAccount.vue')
          },
          {
            path: '/login/forgot',
            component: () => import('@/views/login/component/forgot.vue')
          },
          {
            path: '/login/changePassword',
            component: () => import('@/views/login/component/changePassword.vue')
          },
          {
            path: '/login/ResetPassword',
            component: () => import('@/views/login/component/ResetPassword.vue')
          },
          {
            path: '/login/activityAccount',
            component: () => import('@/views/login/component/activityAccount.vue')
          },
          {
            path: '/login/successActPage',
            component: () => import('@/views/login/component/successActPage.vue')
          },
          {
            path: '/login/callback',
            component: () => import('@/views/login/component/callBack.vue')
          }
        ]
      }
    ]
  }
]

const createRouter = () =>
  new VueRouter({
    mode: 'history', // Disabled due to Github Pages doesn't support this, enable this if you need.
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  ;(router as any).matcher = (newRouter as any).matcher // reset router
}

// 重复点击导航报错处理
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return (VueRouterPush.call(this, to) as any).catch((err: any) => err)
}
export default router
