




















import { Component, Vue } from 'vue-property-decorator'

@Component({
  props: {
    homeLink: {
      type: Boolean
    }
  }
})
export default class Footer extends Vue {
  changeLang(val: any) {
    localStorage.setItem('locale', val)
    this.$i18n.locale = val
  }
  login() {
    this.$router.push('/login')
  }
  gotoPricy() {
    this.$router.push({ path: '/agreement/privacyPolicy' })
  }
  gotoTerms() {
    this.$router.push({ path: '/agreement/termsOfService' })
  }
  gotoAboutUs() {
    // this.$router.push({ path: '/aboutUs/index' })
    window.open('https://gotvoom.myshopify.com/pages/about-us')
  }
}
