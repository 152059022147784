const base = {
  state: {
    hideShadow: false,
    isLockScroll: false,
    scrollTop: 0
  },
  mutations: {
    UPDATE_SHADOW(state, val) {
      state.hideShadow = val
    },
    UPDATE_SCROLL_STATE(state, val) {
      state.isLockScroll = val
    },
    UPDATE_SCROLL_TOP(state, val) {
      state.scrollTop = val
    }

  },
  actions: {
    updateShadow({ commit }, val) {
      commit('UPDATE_SHADOW', val)
    },
    updateLockScrollState({ commit }, val) {
      commit('UPDATE_SCROLL_STATE', val)
    },
    updateScrollTop({ commit }, val) {
      commit('UPDATE_SCROLL_TOP', val)
    }
  }
}
export default {
  // 表示允许  使用namespaced方法使用该模块，必须有
  namespaced: true,
  ...base
}
